import axios, { AxiosRequestConfig } from 'axios'
import { API } from 'src/@core/services/api'
import { request, METHOD } from 'src/@core/services/request'
import Router from 'next/router'
import { Film } from '../components/chinahpo-search'
import { StringSchema } from 'yup'
import { NumberValue } from 'd3'

export interface Pagination {
  pageSize: number
  pageNum: number
}
export interface Login {
  username: string
  password: string

  // last_logged_in: Date | string | null
  // retry_date: Date | string | null
  // is_signed: boolean
  // status: 'active' | 'inactive' | 'trial'
}
export interface Logout {
  username: string
  refreshToken: string

  // last_logged_in: Date | string | null
  // retry_date: Date | string | null
  // is_signed: boolean
  // status: 'active' | 'inactive' | 'trial'
}

export interface Project {
  id?: number
  name: string
  description: string
  createdAt: Date
  isPublic: 0 | 1
  parentId?: number
}

export interface Organization {
  id?: number
  name: string | null
  createdAt: Date | null
  updatedAt: Date | null
}

export enum AnalysisType {
  CASE = 'case_control',
  FAMILY = 'family_analysis',
  BIG_FAMILY = 'big_family',
  CARRIER = 'carrier'
}

export enum AnalysisItemType {
  CASE = 'case_control',
  COHORT_SITES = 'cohort_sites',
  COHORT_PATHWAY = 'cohort_pathway',
  FAMILY_HOMOZYGOUS = 'family_homozygous',
  FAMILY_COMPOUND_HETEROZYGOUS = 'family_compound_heterozygous',
  FAMILY_MT = 'family_mt',
  FAMILY_DOMINANT = 'family_dominant',
  FAMILY_DENOVO = 'family_denovo',
  FAMILY_X_LINKED = 'family_x_linked',
  FAMILY_FAST_TRACK = 'family_fast_track',
  FAMILY_RAW_DATA = 'family_raw_data',
  BIG_FAMILY = 'big_family',
  CARRIER_GLS = 'carrier_gls',
  CARRIER_HOM_HEMI = 'carrier_hom_hemi',
  CARRIER_ALL_SITES = 'carrier_all_sites',
  CARRIER_COMPOUND_HETEROZYGOUS = 'carrier_compound_heterozygous'
}

export interface Sample {
  id?: number
  name: string | null
  userId?: number | null
  geneSetId?: number | null
  genePanelId?: number | null
  projectId: number | null
  version?: string | null
  genomeBuild: 'hg38' | 'hg19' | null
  sampleCode?: string | null
  gender: 'male' | 'female'
  genderPredictV2?: 'male' | 'female'
  status?:
    | 'created'
    | 'fastq_queuing'
    | 'fastq_analyzing'
    | 'fastq_analyzed'
    | 'fastq_error'
    | 'bam_queuing'
    | 'bam_analyzing'
    | 'bam_analyzed'
    | 'bam_error'
    | 'vcf_queueing'
    | 'vcf_annotating'
    | 'vcf_annotated'
    | 'vcf_error'
    | 'result_processing'
    | 'result_processed'
    | 'result_process_error'
    | 'importing'
    | 'imported'
    | 'import_error'
    | null
  fastqcStatus?:
    | 'created'
    | 'queueing'
    | 'processing'
    | 'processed'
    | 'error'
    | null
  fastqcTaskId?: number | null
  cnvBatchStatus?:
    | 'created'
    | 'queueing'
    | 'processing'
    | 'processed'
    | 'error'
    | null
  cnvBatchTaskId?: number | null
  fileSize?: string | null
  prefix?: string | null
  vcfFile?: string | null
  bamFile?: string | null
  isVcfGz?: boolean | null
  originalName?: string | null
  fastqFiles?: object | null
  eType?: 'fastq' | 'vcf' | null
  vcfType?: 'wgs' | 'wes' | 'rna' | 'microarray' | 'gene_panel' | null
  annotatedStart?: Date | null
  annotatedEnd?: Date | null
  readDepth?: string | null
  totalVariants?: number | null
  totalGenes?: number | null
  totalExonicVariants?: number | null
  removeDuplication?: boolean | null
  notifyOnComplete?: boolean | null
  notifyOnError?: boolean | null
  isDelete?: 0 | 1
  isReview?: 0 | 1
  clinicalInformation?: string | null
  terms?: any[] | Film[] | string | null
  igvPath?: string | null
  folderPath?: string | null
  sampleLocation?: 's3' | 'local' | null
  hasSv?: boolean | null
  hasCnv?: boolean | null
  hasSTR?: boolean | null
  hasROH?: boolean | null
  hasTpm?: boolean | null
  hasFastTrack?: boolean | null
  trueGene?: string | null
  type?: string | null
  createdAt?: Date | null
  updatedAt?: Date | null
  project?: Project
  geneSet?: GeneSets
  sampleType?: string | null
  patients?: Patients[]
  user?: User
  avgReadDepth?: number | null
  analysisId?: number | null
}

export interface Permissions {
  id: number
  name: string
  action: string
  resource: string
}

export interface RolePermissions {
  id?: number
  permissionId?: number
  roleId?: number
}

export interface Role {
  id: number
  name: string
  description: string
  rolePermissions?: RolePermissions[] | string[]
  permissions?: Permissions[]
  userRoles?: object[]
}

export enum PaternityValidation {
  MotherInvalid = 'mother_invalid',
  FatherInvalid = 'father_invalid',
  BothInvalid = 'both_invalid',
  Valid = 'valid',
  Unknown = 'unknown'
}

export interface Analysis {
  id: number
  name: string
  analysis_code: string
  disease_model: string
  paternityValidation?: PaternityValidation
  analysisType: string
  version: string
  description: string
  sample_colection: string
  genome_build: string
  variant_ids: string
  parent_id: string
  roi_ids: string
  createdAt: Date | string
  updatedAt: Date | string
  status:
    | 'created'
    | 'queueing'
    | 'processing'
    | 'processed'
    | 'importing'
    | 'imported'
    | 'import_error'
    | 'error'
    | null
  user_id: number
  gene_panel_id: number
  total_variants: number
  genes_number: number
  exonic_variants: number
  project_id: number
  user_share_id: number
  is_shared: number
  samples_number: number
  is_optimized: number
  is_ci_provided: number
  clinical_information: string
  analysisItems?: any
}

// Filter
export enum FilterOperator {
  LTE = 'lte',
  GTE = 'gte',
  GT = 'gt',
  LT = 'lt'
}

export interface GetAnalysisQcDto {
  pValue: number
  pValueOperator: FilterOperator
}

export enum VcfSource {
  HUADA = 'huada',
  OTHER = 'other'
}

export interface User {
  id?: number
  organizationId?: number | null
  username: string | null
  email: string | null
  password: string | null
  phone?: string | null
  vcfSource?: VcfSource
  status?: 'active' | 'inactive' | 'trial' | null
  lastLoggedIn?: Date | null
  lastIpAddress?: string | null
  retryDate?: Date | null
  retryTimes?: Date | null
  trialDate?: Date | null
  isSigned?: boolean | null
  language?: 'zh' | 'en' | null
  createdAt?: Date | null
  updatedAt?: Date | null
  userRoles?: object[]
  role_permission?: {
    permissions: Permissions[]
    role: Role[]
  }[]
}

export interface GeneSets {
  id?: number
  name?: string | null
  userId?: number | null
  isDefault?: boolean | null
  path?: string | null
  isApproved?: boolean | null
  isGenderPredictable?: boolean | null
  createdAt?: Date | null
  updatedAt?: Date | null
}

export interface WhiteLists {
  id?: number
  name?: string | null
  userId?: number | null
  isDefault?: boolean | null
  path?: string | null
  createdAt?: Date | null
  updatedAt?: Date | null
}

export interface GenePanel {
  id?: number
  userId?: number | null
  panelName?: string | null
  geneNames?: string | null
  isDelete?: boolean | null
  createdAt?: Date | null
  updatedAt?: Date | null
}

export interface Patients {
  id?: number
  userId?: number | null
  sampleId?: number | null
  fileId?: number | null
  fileName?: string | null
  receiveDate?: Date | null
  physicianName?: string | null
  infirmaryName?: string | null
  departmentName?: string | null
  caseNumber?: string | null
  patientName?: string | null
  dateOfBirth?: null | Date
  gender?: string | null
  ethnicity?: string | null
  phenotype?: string | null
  comments?: string | null
  firstName?: string | null
  lastName?: string | null
  patientCode?: string | null
  address?: string | null
  city?: string | null
  state?: string | null
  zipCode?: string | null
  phone?: string | null
  sampleType?: string | null
  reportType?: string | null
  arrivalDate?: string | null
  sentToLab?: string | null
  resultsIn?: string | null
  dateSent?: string | null
  labBatch?: string | null
  provider?: string | null
  doctorEmail?: string | null
  fax?: string | null
  createdAt?: Date | null
  updatedAt?: Date | null
}

export interface Reports {
  id?: number
  userId?: number | null
  templateId?: number | null
  objectId?: number | null
  objectType?: 'sample' | 'analysis' | null
  name?: string | null
  templatePath?: string | null
  result?: 'positive' | 'negative' | null
  language?: 'zh' | 'en' | null
  metadata?: string | null
  reportJson?: string | null
  createdAt?: Date | null
  updatedAt?: Date | null
}

export enum ObjectType {
  SAMPLE = 'sample',
  ANALYSIS = 'analysis'
}

export interface ReportsVariants {
  id?: number
  objectId: number | null
  objectType: ObjectType
  snvVariants: object | string | null
  cnvVariants: object | string | null
  createdAt: Date | null
  updatedAt: Date | null
}

export interface AnalyzeArticleDto {
  title: string
  abstract?: string
  url?: string
}

export interface AnalyzeVariantDto {
  variantInfo: string
}

export interface ChatClaudeDto {
  messages: any
}

axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = JSON.parse(localStorage.getItem('token') || '{}')
    const user = JSON.parse(localStorage.getItem('user') || '{}')

    if (token) {
      config.headers.Authorization = `Bearer ${token.accessToken}`

      config.headers.organization = `${user.organizationId}`
    }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
let isRefreshing = false

axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return axios(originalRequest)
      }

      originalRequest._retry = true

      isRefreshing = true

      return new Promise(function (resolve, reject) {
        const token = JSON.parse(localStorage.getItem('token') || '{}')
        const user = JSON.parse(localStorage.getItem('user') || '{}')

        axios
          .post('/api/auth/refresh/', {
            username: user.email,
            refreshToken: token.refreshToken
          })
          .then((response) => {
            const newToken = response.data

            localStorage.setItem('token', JSON.stringify(newToken))

            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + newToken

            originalRequest.headers.Authorization = 'Bearer ' + newToken

            resolve(axios(originalRequest))
          })
          .catch((err) => {
            reject(err)
          })
          .then(() => {
            isRefreshing = false
          })
      })
    } else {
      if (error.response && error.response.status === 403) {
        // 处理 403 错误，跳转到无权限页面或其他处理
        console.error('Forbidden request. Redirecting to no access page.')

        Router.push('/pages/login') // 请替换为实际的无权限页面路径
      }

      return Promise.reject(error)
    }
  }

  // function (error) {
  //   // 对响应错误做点什么
  //   if (error.response && error.response.status === 401) {
  //     // 处理 401 错误，比如跳转到登录页面
  //     console.log('Unauthorized request. Redirecting to login page.')

  //     // const router = new Router()

  //     // 例如：
  //     Router.push('/pages/login')

  //     // router.push('/login')
  //   }

  //   return Promise.reject(error)
  // }
)

export async function getSamplesSvn(param: any, sampleId: number) {
  return request(
    `${API.SAMPLES}sample/snv/${sampleId}/`,
    METHOD.POST,
    param,
    {}
  )
}
export interface Variation {
  CLINVAR_clnsig_final: string
  CLINVAR_gold_stars: string
  dosage_sensitive_info: any[]
  clinvar_info: any[]
  _id: string
  sampleId: number
  readDepth: number
  alleleFrequency: number
  allele_frequency: any
  mom_allele_frequency: number
  dad_allele_frequency: number
  coverage: string
  dad_coverage: string
  dad_id: string
  mom_id: string
  mom_coverage: string
  hg_genes: string[]
  type: string
  score_details: string
  genes: string[]
  chrom: number
  inputPos: number
  f_score: number
  dad_fscore: number
  mom_fscore: number
  g_score: number
  dad_gscore: number
  mom_gscore: number
  REF: string
  ALT: string
  rs_id: string
  transcript: string
  nuc_change: string
  cNomen: string
  pNomen: string
  cosmicIds: string
  clinVarIds: string
  gene: string
  coding_effect: string
  var_location: string
  ExAC_ALL: string
  ExAC_AFR: string
  ExAC_AMR: string
  ExAC_EAS: string
  ExAC_FIN: string
  ExAC_NFE: string
  ExAC_OTH: string
  ExAC_SAS: string
  gnomAD_exome_ALL: number
  gnomAD_exome_AFR: number
  gnomAD_exome_AMR: number
  gnomAD_exome_ASJ: number
  gnomAD_exome_EAS: number
  gnomAD_exome_FIN: number
  gnomAD_exome_NFE: number
  gnomAD_exome_OTH: number
  gnomAD_exome_SAS: string
  SIFT_score: string
  Polyphen2_HDIV_score: string
  CADD_phred: number
  CADD_raw: number
  CLINSIG_priority: number
  CLINSIG: string
  CLINSIG_final: string
  '1000g_AF': string
  '1000g_EAS_AF': string
  '1000g_AMR_AF': string
  transcriptIds: string
  cosmics: string
  chrom_pos_ref_alt_gene: string
  '#Chr_Start_Ref_Alt_Ref': object
  Consequence: string
  varHGVSc: string
  varHGVSp: string
  exon: string
  INTRON: string
  DOMAINS: string
  '1000g_AFR_AF': string
  '1000g_EUR_AF': string
  '1000g_SAS_AF': string
  AA_AF: string
  EA_AF: string
  MAX_AF: number
  MAX_AF_POPS: string
  SOMATIC: string
  PHENO: string
  PUBMED: string
  MOTIF_NAME: string
  MOTIF_POS: string
  HIGH_INF_POS: string
  MOTIF_SCORE_CHANGE: string
  CADD_PHRED: number
  CADD_RAW: number
  CANONICAL: string
  CLINSIG_PRIORITY: number
  CLINSIG_FINAL: string
  hasClinicalSynopsis: number
  lossOfFunction: number
  loss_of_function: number
  inputPosInt: number
  gnomAD_exome_ALL_Int: number
  gnomAD_exome_AFR_Int: number
  gnomAD_exome_AMR_Int: number
  CDS_position: string
  selected_variant: number
  HGNC_SYMONYMS: string
  HGNC_PRE_SYMBOL: string
  VariantMatching: string
  withdrawn_gene: number
  SIFT: string
  Polyphen2: string
  gnomAD_genome_ALL: number
  gnomAD_genome_AFR: number
  gnomAD_genome_AMR: number
  gnomAD_genome_ASJ: number
  gnomAD_genome_EAS: number
  gnomAD_genome_FIN: number
  gnomAD_genome_NFE: number
  gnomAD_genome_OTH: number
  gnomADe_ALL: string
  gnomADe_AFR: string
  gnomADe_AMR: string
  gnomADe_ASJ: string
  gnomADe_EAS: string
  gnomADe_FIN: string
  gnomADe_NFE: string
  gnomADe_OTH: string
  gnomADe_SAS: string
  Clinvar_VARIANT_ID: string
  masterMind_MMID3: string
  masterMind_MMCNT3: string
  masterMind_GENE: string
  GeneSplicer: string
  IMPACT: string
  STRAND: number
  VARIANT_CLASS: string
  VAR_GENE: string
  VAR_SCORE: number
  QUAL: number
  FILTER: string
  GT: string
  Trimmed_variant: string
  AF_hom: string
  AF_het: string
  pop_AF_hom: string
  pop_AF_het: string
  hg38_position: string
  CLINVAR_clnsig: string
  CLINVAR_CLNSIGCONF: string
  MQ: number
  MANE_TR: string
  CLNACC: string
  CLNSIG_BTG: string
  review_status: string
  last_evaluated: string
  gold_stars: string
  consensus_score: string
  curation: string
  cosmic_ids: string
  cosmic: string
  BTG_Concensus: string
  HGMD: string
  InterVar: string
  ACMG_classification: string
  ACMG_classification_modified: string
  AutoGVP_classification: string
  ACMG_priority: number
  AutoGVP_priority: number
  inheritance: string
  c_nomen: string
  p_nomen: string
  inheritance_score: number
  is_compounds_hets: number
  index: number
  PGx: string
  ml_gene_score: number
  ml_dominant_g_score: number
  ml_recessive_g_score: number
  QUAL_NORM: number
  MQ_NORM: number
  DP_NORM: number
  final_score: number
  classification_result: any[]
  pos: number
  OMIM?: VariantDetailOmimData
  LitVarArticles?: any
  Articles?: any
  OMIMArr?: VariantDetailOmimData[]
  SpliceAI_max_score: number
  AM_class: string
  AM_pathogenicity: string
  REVEL_score: number
  user_classification?: VariantClassification
  user_acmg_classification?: ACMGClassification
  user_variant_comments?: VariantComments
  acmg_evidences?: string[]
  transcript_list: TranscriptInfo[]
  Index: IVariantIndexData
  END_POS: string
  CN: any
  LEN: number
  exon_number: string
  P_snvindel_nb: string
  P_snvindel_phen: string
  ClassifyCNV_evidences: string
  AnnotSV_ranking_score: number
  total_score: number
  classification: string
  ccnv_classification: string
  highlight_genes: string
  confidence_interval: string
  term: string
  count: number
  odds_ratio: number
  gene_ratio: number
  hits_ratio: number
  p_value: number
  adjusted_p_value: number
  total_cases: number
  total_controls: number
  total_samples: number
  case_samples: number[]
  control_samples: number[]
  SV_start: number
  SV_end: number
  start: number
  end: number
  SV_length: number
  SV_type: string
  AnnotSV_evidences: string
  DDD_HI_percent: string
  LOEUF_bin: string
  GnomAD_pLI: string
  HI: string
  TS: string
  RU: string
  GangSTR_repcn: string
  STR_Result: boolean
  GangSTR_repci: string
  GangSTR_dp: number
  GangSTR_q: number
  EH_repcn: string
  EH_repci: string
  EH_ref: number
  gene_info?: SvGeneInfo
  omim_info?: SvVariantOmimInfo[]
  ExomeDepth_omim_genes: string[]
  ExomeDepth_genes: string[]
  ExomeDepth_other_genes: string[]
  MitoMap: IMitoMapSearchSource
  members_data: any[]
  samples_info: any[]
}

export type TranscriptInfo = {
  transcript_id: string
  gene: string
  gene_name: string
}

export type SvOmimGeneDetail = {
  symbol: string
  OMIM_ID: string
}

export type SvVariantOmimInfo = {
  omim_id: string
  omim_phenotype: string
  omim_inheritance: string
}

export type SvGeneInfo = {
  omim_disease_genes: SvOmimGeneDetail[]
  omim_genes: SvOmimGeneDetail[]
  other_genes: string[]
}

export type CnvVariants = {
  _id: string
  chrom: number
  pos: number
  REF: string
  ALT: string
  END_POS: string
  CN: string
  LEN: number
  exon_number: string
  gene: string
  classification: string
  highlight_genes: string
}

export class VariantComments {
  '_id': string
  'key': string
  'comments': IVariantComment[]
}

export interface IVariantComment {
  _id: string
  user_id: number
  post_time: string
  content: string
  user_data_name: string
}

export interface FilterQuery {
  readDepthOperator: string
  readDepthValue: number
  zygosity: string
  indelOperator: string
  indelValue: number
  snvOperator: string
  snvValue: number
  geneNameIn: string[]
  geneNameOut: string[]
  inheritance: string[]
  gnomAD: string[]
  gnomADOperator: string
  gnomADValue: number
  insilico: string[]
  CADDValue: number
  qualMin: number
  qualMax: number
  indexMin: number
  indexMax: number
  classification: string[]
  acmgClassification: string[]
  sequenceOntology: string[]
  rs_id: string[]
  gscoreValue: number
  gscoreOperator: string
  fscoreValue: number
  fscoreOperator: string
  revelValue: number
  revelOperator: string
  userLocusIn: number[]
  userLocusOut: number[]
  chrom: string
  posStart: number
  posEnd: number
  phenotypes: string[]
}

export interface Filters {
  id?: number
  user_id: number | null
  name: string | null
  filter: object | null
  type: FilterType
  createdAt: Date | null
}

export interface CurrentFilter {
  id?: number
  userId: number | null
  filter: object | null
  type: FilterType
  objectType: ObjectType
  objectId: number
  createdAt: Date | null
}

export interface Category {
  id?: number
  userId: number | null
  name: string | null
  createdAt: string | null
  updatedAt: string | null
  isPublic: 0 | 1
}

export interface VariantList {
  id?: number
  categoryId: number | null
  key: string | null
  chrom: string | null
  pos: number | null
  ref: string | null
  alt: string | null
  pnomen: string | null
  cnomen: string | null
  gene: string | null
}

export interface ShareSample {
  id?: number
  userId?: number | null
  userShareId: number | null
  sharedSample: number | null
  createdAt: Date | null
  updatedAt: Date | null
  type: ObjectType
}

export interface SampleLabels {
  id?: number
  userId?: number | null
  labelName: string
  sampleId: number
}

export interface GetVennDataDto {
  analysisId: number
  analysisItemId: number
  sampleIds: number[]
}

export interface IVenDataResult {
  ids: string[]
  scores: number[]
  total: number
}

export enum FilterType {
  SNV = 'SNV',
  CNV = 'CNV',
  SV = 'SV',
  TPM = 'TPM'
}

export interface IVariantRohList {
  totalMegabase: string
  megabasePercentage: string
  data: IVariantRohData[]
}

export interface IVariantRohData {
  chrom: string
  begin: string
  end: string
  size: string
  nbVariants: string
  percentageHomozygosity: string
  index: number
}

export type VariantDetailMatchTranscript = {
  ENST: string
  length: string
  refseq: string
  HGVSc?: string
  HGVSp?: string
}

export type VariantDetailHGMDInfo = {
  name: string
  value: string
}

export type VariantDetailGeneInfo = {
  function: string
  functionZh: string
  condition: string
  conditionZh: {
    name: string
    detail: string
  }[]
}

export type VariantDetailGenePLI = IGenePliSearchSource
export type VariantDetailClinvarMaxAF = IClinvarMaxAFSearchSource
export type VariantDetailClinvarGraph = IVariantDetailClinvarGraph
export interface IVariantDetailClinvarGraph extends IClinsigSearchSource {
  bold: boolean
}
export interface IClinsigSearchSource {
  key: string
  chrom: string
  pos: number
  ref: string
  alt: string
  variant_id: string
  clinsig: string
  clinsig_ch: string
}

export interface IClinvarMaxAFSearchSource {
  gene_name: string
  max_af: string
  max_af_pop: string
}
export interface IGenePliSearchSource {
  gene: string
  transcript: string

  syn_exp: string
  syn_obs: string
  syn_z: string
  syn_oe: string
  syn_oe_lower: string
  syn_oe_upper: string

  mis_exp: string
  mis_obs: string
  mis_z: string
  mis_oe: string
  mis_oe_lower: string
  mis_oe_upper: string

  lof_exp: string
  lof_obs: string
  lof_z: string
  lof_oe: string
  lof_oe_lower: string
  lof_oe_upper: string

  pLI: string

  lof_hc_lc_exp: string
  lof_hc_lc_obs: string
  lof_hc_lc_oe: string
  lof_hc_lc_pLI: string

  constraint_flags: string
}

export interface VariantDetail {
  transcripts: VariantDetailMatchTranscript[]
  clinicalSynopsis: Record<string, any>
  hgmd: VariantDetailHGMDInfo[]
  variantInfo: Variation
  acmg: string[]
  acmgEvidences: string[]
  acmgDataList: Record<string, any>
  geneInfo: VariantDetailGeneInfo
  genePli: VariantDetailGenePLI
  clinVarMaxAF: VariantDetailClinvarMaxAF
  clinvarGraph: VariantDetailClinvarGraph[]
  geneMim: string
  hgncId: number
}

export enum GenomeBuild {
  HG38 = 'hg38',
  HG19 = 'hg19'
}

export interface GetVariantDetailDto {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
  gene: string
}

export enum ACMGClassification {
  LIKELY_BENIGN = 'Likely benign',
  LIKELY_PATHOGENIC = 'Likely pathogenic',
  PATHOGENIC = 'Pathogenic',
  UNCERTAIN_SIGNIFICANCE = 'Uncertain significance',
  BENIGN = 'Benign'
}

export enum VariantClassification {
  PATHOGENIC = 'pathogenic',
  LIKELY_PATHOGENIC = 'likely pathogenic',
  DRUG_RESPONSE = 'drug response',
  UNCERTAIN_SIGNIFICANCE = 'uncertain significance',
  LIKELY_BENIGN = 'likely benign',
  BENIGN = 'benign'
}

export interface UpdateVariantACMGClassificationDto {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
  gene: string
  genomeBuild: GenomeBuild
  evidences: string[]
  classification: ACMGClassification
}

export interface GetManyVariantIndexDto {
  genomeBuild: GenomeBuild
  variants: GetVariantIndexDto[]
}

export interface GetVariantIndexDto {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
}

export interface IVariantIndexResult {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
}
export interface IVariantIndexData {
  key: string
  accountTotal: number
  userSamples: number
  glsSamples: number
  btgSamples: number
  platformTotal: number
  currentSamples: Partial<Sample>[]
  dbSamples: Partial<Sample>[]
  notAvaiableSamplesTotal: number
}

export interface IVariantKnowledgeIndexData {
  key: string
  total: number
  samples: IVariantKnowledgeIndexSampleData[]
}

export interface IVariantKnowledgeIndexSampleData {
  name: string
  trueGene: string
  info: string
  distractorGenes: string[]
}
export interface IVariantIndexResult extends IVariantIndexData {
  dbSamples: IVariantKnowledgeIndexSampleData[]
  notAvaiableSamplesTotal: number
}

export interface UpdateVariantClassificationDto {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
  gene: string
  genomeBuild: GenomeBuild
  classification: VariantClassification
}

export interface GetArticlesDto {
  gene: string
  rsId: string
  cnomen: string
  pnomen: string
  pubmed: string
  omimPubmed: string
  searchScholar?: boolean
}

export interface GetVariantLiteratureDto {
  variant: string
  assembly: string
}
export interface ISamplesQcRohData {
  imageUrl: string
  totalMegabase: string
  megabasePercentage: string
}

export interface ISamplesQcData {
  roh: ISamplesQcRohData
  fastqc: ISamplesFastQCData
}

export interface ISamplesFastQCData {
  metricsData: ISamplesFastqMetricsInfo
  pctData: ISamplesFastqPCTData[]
  metricsReports: string[]
  scatterUrl: string
}

export interface ISamplesFastqPCTData {
  name: string
  value: number
}

export interface ISamplesFastqMetricsInfo {
  rawReads: string

  readsMapped: string
  readsMappedPercent: string

  readsAfterDeduped: string
  readsAfterDedupedPercent: string

  readsOnTargetRegionsPercent: string
}

export enum FilterProtocol {
  OR = 'or',
  AND = 'and'
}

export enum VariantChrom {
  CHR_1 = '1',
  CHR_2 = '2',
  CHR_3 = '3',
  CHR_4 = '4',
  CHR_5 = '5',
  CHR_6 = '6',
  CHR_7 = '7',
  CHR_8 = '8',
  CHR_9 = '9',
  CHR_10 = '10',
  CHR_11 = '11',
  CHR_12 = '12',
  CHR_13 = '13',
  CHR_14 = '14',
  CHR_15 = '15',
  CHR_16 = '16',
  CHR_17 = '17',
  CHR_18 = '18',
  CHR_19 = '19',
  CHR_20 = '20',
  CHR_21 = '21',
  CHR_22 = '22',
  CHR_X = 'X',
  CHR_Y = 'Y',
  CHR_M = 'M'
}

export type VariantDetailOmimData = {
  omimData: {
    phenoName: string
    phenoMim: string
    location: string
    clinicalSynopsis: Record<string, any>
    pheno_name_cn: string
    phenoDescription: Record<string, any>[]
    phenoClinicalFeatures: Record<string, any>[]
    phenoDescriptionCN: Record<string, any>[]
    phenoClinicalFeaturesCN: Record<string, any>[]
  }[]
  geneName: string
  geneMim: string
  pubmedIds: string
}

export type TranslateDto = {
  sourceText: string
  sourceLanguage: string
  targetLanguage: string
}

export type GetSampleCnvQcDto = {
  chrom: VariantChrom
  start: number
  end: number
}
export type CnvPlotDataType = {
  chrom: VariantChrom
  start: number
  end: number
  raw_cn: number
  index: number
}

export type CnvPlotType = {
  chrom: VariantChrom
  data: CnvPlotDataType
}

export type TranslateBulkDto = {
  sourceText: string[]
  sourceLanguage: string
  targetLanguage: string
}

export enum AnalysisStatus {
  CREATED = 'created',
  QUEUEING = 'queueing',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  IMPORTING = 'importing',
  IMPORTED = 'imported',
  IMPORT_ERROR = 'import_error',
  ERROR = 'error'
}

export type AnalysisItem = {
  id?: number
  analysisId: number | null
  type: AnalysisItemType
  status: AnalysisStatus
  totalSamples: number | null
  totalVariants: number | null
  totalGenes: number | null
  totalExonicVariants: number | null
  createdAt: Date | null
  updatedAt: Date | null
  analysis?: Analysis
}

export enum TaskStatus {
  CREATED = 'created',
  QUEUEING = 'queueing',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  ERROR = 'error'
}

export enum ServerName {
  LOCAL = 'local_server'
}

export type TaskWesCnvBatchDto = {
  id?: number
  userId: number | null
  status: TaskStatus
  serverName: ServerName
  createdAt: Date | null
  updatedAt: Date | null
}

export type GetTermsByClinicalInfoDto = {
  clinicalInfo: string
}

export type CreateVariantCommentDto = {
  chrom: VariantChrom
  pos: number
  REF: string
  ALT: string
  postTime: string
  content: string
  genomeBuild: GenomeBuild
  userName: string
}

export type GetMitoMapDataDto = {
  pos: number
  REF: string
  ALT: string
}

export type IMitoMapSearchSource = {
  locus: string
  allele: string
  key: string
  amino_acid_change: string
  plasmy_reports: string
  disease: string
  status: string
  GB_freq: string
  GB_seqs: string
  pubmed_ids: string
  disease_ch: string
}

export type UpdateVariantCommentDto = {
  postTime: string
  content: string
}

export type RemoveVariantCommentDto = {
  commentId: string
}
export enum OssSignedUrlType {
  BAM = 'bam',
  VCF = 'vcf'
}

// 获取项目接口
export const fetchProjects = async (params: Pagination | Project) => {
  const response = await axios.get('/api/projects/', { params })

  return response.data
}

export const saveProjects = async (param: Project) => {
  // 新建项目接口
  const response = await axios.post('/api/projects/', param)

  return response.data
}

export const createProject = async (param: Project) => {
  const response = await axios.post('/api/projects/', param)

  return response.data
}

export const updateProject = async (project: Project) => {
  const response = await axios.patch(`/api/projects/${project.id}`, project)

  return response.data
}

export const deleteProject = async (projectId: number) => {
  await axios.delete(`/api/projects/${projectId}`)
}

// 获取分析接口
export const fetchAnalysis = async (params: Pagination | Analysis) => {
  const response = await axios.get('/api/analysis/', { params })

  return response.data
}

export const fetchAnalysisById = async (id: string | string[] | undefined) => {
  const response = await axios.get(`/api/analysis/${id}/`)

  return response.data
}

export const fetchAnalyzeInfoByAnalysisId = async (
  id: string | string[] | undefined
) => {
  const response = await axios.get(
    `/api/analysis/analyzeInfoByAnalysisId/${id}/`
  )

  return response.data
}

// 新建分析接口
export const saveAnalysis = async (params: any) => {
  const response = await axios.post('/api/analysis/', params)

  return response.data
}

// 删除分析接口
export const deleteAnalysis = async (id: number | string) => {
  const response = await axios.delete(`/api/analysis/${id}/`)

  return response.data
}

// 修改分析接口
export const editAnalysis = async (params: any) => {
  const response = await axios.patch(`/api/analysis/${params.id}/`, params)

  return response.data
}

export const getCohortPlotData = async (id: number | string) => {
  const response = await axios.get(`/api/analysis/qc/plot/${id}/`)

  return response.data
}

export const getCohortChartQc = async (
  id: number | string,
  params: GetAnalysisQcDto
) => {
  const response = await axios.post(`/api/analysis/qc/chart/${id}/`, params)

  return response.data
}

// 获取样本接口
export const fetchSamples = async (params: Pagination, projectId: number) => {
  const response = await axios.get(
    `/api/samples/?pageNum=${params.pageNum}&pageSize=${params.pageSize}&projectId=${projectId}`
  )

  return response.data
}

export const fetchSample = async (
  params: Pagination | Sample | { id: string | string[] | undefined } | any
) => {
  const response = await axios.get(`/api/samples/`, { params })

  return response.data
}

export const fetchSampleById = async (id: string | string[] | undefined) => {
  const response = await axios.get(`/api/samples/${id}/`)

  return response.data
}

export const editSample = async (params: Sample) => {
  const response = await axios.patch(`/api/samples/${params.id}/`, params)

  return response.data
}

export const createSample = async (params: Sample) => {
  const response = await axios.post(`/api/samples/`, params)

  return response.data
}

export const deleteSample = async (id: number | string) => {
  const response = await axios.delete(`/api/samples/${id}/`)

  return response.data
}

export const getDashbordData = async (params: any) => {
  const response = await axios.get(`/api/samples/getDashbordData/`, { params })

  return response.data
}

// 根据样本id查询分析信息
export const fetchAnalyzeInfoBySampleId = async (
  sampleId: string | string[] | undefined
) => {
  const response = await axios.get(
    `/api/analysis-samples/getAnalyzeInfoBySampleId/?sampleId=${sampleId}`
  )

  return response.data
}

export const updataAnalyzeSample = async (params: any) => {
  const response = await axios.post(`/api/analysis-samples/updateById`, params)

  return response.data
}

// 根据样本id查询信息
export const fetchInfoBySampleId = async (
  sampleId: string | string[] | undefined
) => {
  const response = await axios.get(
    `/api/samples/getInfoBySampleId/?sampleId=${sampleId}`
  )

  return response.data
}

export const fetchOrganizations = async (params: Organization | Pagination) => {
  const response = await axios.get(`/api/organizations/`, {
    params
  })

  return response.data
}

export const createOrganization = async (param: Organization) => {
  const response = await axios.post('/api/organizations/', param)

  return response.data
}

export const updateOrganization = async (param: Organization) => {
  const response = await axios.patch(`/api/organizations/${param.id}`, param)

  return response.data
}

export const deleteOrganization = async (id: number | string) => {
  const response = await axios.delete(`/api/organizations/${id}`)

  return response.data
}

// 根据用户列表
export const fetchUserList = async (
  params: Pagination | { email: string } | any
) => {
  const response = await axios.get(`/api/users/`, {
    params
  })

  return response.data
}

export const fetchUser = async (email: string) => {
  const response = await axios.get(`/api/users/${email}`)

  return response.data
}

export const createUser = async (param: User) => {
  const response = await axios.post('/api/users/', param)

  return response.data
}

export const updateUser = async (user: User) => {
  const response = await axios.patch(`/api/users/${user.id}/`, user)

  return response.data
}

export const deleteUser = async (userId: number) => {
  const response = await axios.delete(`/api/users/${userId}/`)

  return response.data
}

export const login = async (params: Login) => {
  const response = await axios.post(`/api/auth/login/`, params)

  return response.data
}

export const logout = async (params: Logout) => {
  const response = await axios.post(`/api/auth/logout/`, params)

  return response.data
}

export const getPublicHomRegions = async (id: string) => {
  const response = await axios.post(
    `/api/auth/getPublicHomRegions/${id}/`,
    {},
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  return response.data
}

export const getQCData = async (id: string) => {
  const response = await axios.get(`/api/samples/qc/data/${id}/`)

  return response.data
}

export const getCnvPlot = async (id: string, params: GetSampleCnvQcDto) => {
  const response = await axios.post(`/api/samples/qc/cnvPlot/${id}/`, params)

  return response.data
}

export const getCnvChromPlot = async (id: string) => {
  const response = await axios.get(`/api/samples/qc/cnvChromPlot/${id}/`)

  return response.data
}

export const getAlleleFrequencyQC = async (
  id: string,
  params: {
    start: number
    end: number
  }
) => {
  const response = await axios.post(
    `/api/samples/qc/alleleFrequency/${id}/`,
    params
  )

  return response.data
}

export const getReadDepth = async (
  id: string,
  params: {
    start: number
    end: number
  }
) => {
  const response = await axios.post(`/api/samples/qc/readDepth/${id}/`, params)

  return response.data
}

export const refresh = async (params: Logout) => {
  const response = await axios.post(`/api/auth/refresh`, params)

  return response.data
}

// 权限
export const createPermissions = async (param: Permissions) => {
  const response = await axios.post('/api/permissions/', param)

  return response.data
}

export const getPermissions = async () => {
  const response = await axios.get('/api/permissions/')

  return response.data
}

export const editPermissions = async (param: Permissions) => {
  const response = await axios.patch('/api/permissions/', param)

  return response.data
}

export const deletePermissions = async (id: number) => {
  const response = await axios.delete(`/api/permissions/${id}/`)

  return response.data
}

// 角色
export const createRoles = async (param: Role) => {
  const response = await axios.post('/api/roles/', param)

  return response.data
}

export const getRoles = async () => {
  const response = await axios.get('/api/roles/')

  return response.data
}

export const editRoles = async (param: Role) => {
  const response = await axios.patch(`/api/roles/${param.id}/`, param)

  return response.data
}

export const deleteRoles = async (id: number) => {
  const response = await axios.delete(`/api/roles/${id}/`)

  return response.data
}

// 搜索Chinahpo信息
export const searchChinahpo = async (params: {
  like: string
  type?: null | number
}) => {
  const response = await axios.post(`/api/samples/chinahpoSearch/`, params)

  return response.data
}

// 白名单
export const createWhiteLists = async (param: WhiteLists) => {
  const response = await axios.post('/api/whitelists/', param)

  return response.data
}

export const getWhiteLists = async (params: WhiteLists | Pagination) => {
  const response = await axios.get('/api/whitelists/', { params })

  return response.data
}

export const editWhiteLists = async (param: WhiteLists) => {
  const response = await axios.patch(`/api/whitelists/${param.id}/`, param)

  return response.data
}

export const deleteWhiteLists = async (id: number) => {
  const response = await axios.delete(`/api/whitelists/${id}/`)

  return response.data
}

// 目标区域
export const createGeneSets = async (param: GeneSets) => {
  const response = await axios.post('/api/gene-sets/', param)

  return response.data
}

export const getGeneSets = async (params: GeneSets | Pagination) => {
  const response = await axios.get('/api/gene-sets/', { params })

  return response.data
}

export const editGeneSets = async (param: GeneSets) => {
  const response = await axios.patch(`/api/gene-sets/${param.id}/`, param)

  return response.data
}

export const deleteGeneSets = async (id: number) => {
  const response = await axios.delete(`/api/gene-sets/${id}/`)

  return response.data
}

// 基因Panel
export const createGenePanel = async (param: GenePanel) => {
  const response = await axios.post('/api/gene-panel/', param)

  return response.data
}

export const getGenePanel = async (params: GenePanel | Pagination) => {
  const response = await axios.get('/api/gene-panel/', { params })

  return response.data
}

export const editGenePanel = async (param: GenePanel) => {
  const response = await axios.patch(`/api/gene-panel/${param.id}/`, param)

  return response.data
}

export const deleteGenePanel = async (id: number) => {
  const response = await axios.delete(`/api/gene-panel/${id}/`)

  return response.data
}

// patients
export const createPatients = async (param: Patients) => {
  const response = await axios.post('/api/patients/', param)

  return response.data
}

export const getPatients = async (params: Patients | Pagination) => {
  const response = await axios.get('/api/patients/', { params })

  return response.data
}

export const editPatients = async (param: Patients) => {
  const response = await axios.patch(`/api/patients/${param.id}/`, param)

  return response.data
}

export const deletePatients = async (id: number) => {
  const response = await axios.delete(`/api/patients/${id}/`)

  return response.data
}

// reports
export const createReports = async (param: Reports) => {
  const response = await axios.post('/api/reports/', param)

  return response.data
}

export const getReports = async (params: Reports | Pagination) => {
  const response = await axios.get('/api/reports/', { params })

  return response.data
}

export const getReportsByID = async (id: number) => {
  const response = await axios.get(`/api/reports/${id}/`)

  return response.data
}

export const editReports = async (param: Reports) => {
  const response = await axios.patch(`/api/reports/${param.id}/`, param)

  return response.data
}

export const deleteReports = async (id: number) => {
  const response = await axios.delete(`/api/reports/${id}/`)

  return response.data
}

// variants_report
export const createVariantReports = async (param: ReportsVariants) => {
  const response = await axios.post('/api/report-variants/', param)

  return response.data
}

export const getVariantReports = async (
  params: ReportsVariants | Pagination
) => {
  const response = await axios.get('/api/report-variants/', { params })

  return response.data
}

export const getVariantReportsById = async (id: number) => {
  const response = await axios.get(`/api/report-variants/${id}/`)

  return response.data
}

export const editVariantReports = async (param: ReportsVariants) => {
  const response = await axios.patch(`/api/report-variants/${param.id}/`, param)

  return response.data
}

export const deleteVariantReports = async (id: number) => {
  const response = await axios.delete(`/api/report-variants/${id}/`)

  return response.data
}

// oss
export const upload = async (params: {
  file: FormData
  setProgress: any
  getFileSize: any
}) => {
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: any) => {
      params.setProgress && params.setProgress(progressEvent.progress * 100)

      params.getFileSize &&
        params.getFileSize(progressEvent.total / 1024 / 1024)
    }
  }
  const response = await axios.post(`/api/oss/upload/`, params.file, config)

  return response.data
}

export const uploadFq = async (params: {
  file: FormData
  setProgress: any
  getFileSize: any
}) => {
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: any) => {
      params.setProgress && params.setProgress(progressEvent.progress * 100)

      params.getFileSize &&
        params.getFileSize(progressEvent.total / 1024 / 1024)
    }
  }
  const response = await axios.post(
    `/api/oss/fastq_uploads/`,
    params.file,
    config
  )

  return response.data
}

export const downLoad = async (params: { fileKey: string }) => {
  const response = await axios.get(`/api/oss/download/`, {
    // responseType: 'blob',
    params
  })

  return response.data
}

export const getSignedUrl = async (params: {
  type: OssSignedUrlType
  obj: string
}) => {
  const response = await axios.post(`/api/oss/getSignedUrl/`, params)

  return response.data
}

export const assumeRole = async () => {
  const response = await axios.get(`/api/oss/assumeRole/`)

  return response.data
}

// filter
export const saveFilter = async (filter: Filters) => {
  const response = await axios.post(`/api/filters/`, filter)

  return response.data
}

export const fetchFilter = async (params: Filters | Pagination) => {
  const response = await axios.get(`/api/filters/`, { params })

  return response.data
}

export const editFilter = async (params: Filters) => {
  const response = await axios.patch(`/api/filters/${params.id}/`, params)

  return response.data
}

export const deleteFilter = async (id: number) => {
  const response = await axios.delete(`/api/filters/${id}`)

  return response.data
}

// CurrentFilter
export const saveCurrentFilter = async (filter: CurrentFilter) => {
  const response = await axios.post(`/api/current-filter/`, filter)

  return response.data
}

export const fetchCurrentFilter = async (
  params: CurrentFilter | Pagination
) => {
  const response = await axios.get(`/api/current-filter/`, { params })

  return response.data
}

export const editCurrentFilter = async (params: CurrentFilter) => {
  const response = await axios.patch(
    `/api/current-filter/${params.id}/`,
    params
  )

  return response.data
}

export const deleteCurrentFilter = async (id: number) => {
  const response = await axios.delete(`/api/current-filter/${id}`)

  return response.data
}

// fast_track
export async function getVariantsByFastTrack(param: any, sampleId: number) {
  return request(
    `${API.SAMPLES}sample/fast_track/${sampleId}/`,
    METHOD.POST,
    param,
    {}
  )
}

// CNV
export async function getVariantsByCNV(param: any, sampleId: number) {
  return request(
    `${API.SAMPLES}sample/cnv/${sampleId}/`,
    METHOD.POST,
    param,
    {}
  )
}

// cohort
export async function getVariantsByCohort(param: any, analysisId: number) {
  return request(
    `${API.SAMPLES}analysis/cohort/${analysisId}/`,
    METHOD.POST,
    param,
    {}
  )
}

export async function getVariantsByPathway(param: any, analysisId: number) {
  return request(
    `${API.SAMPLES}analysis/pathway/${analysisId}/`,
    METHOD.POST,
    param,
    {}
  )
}

export async function getSTRData(param: any, analysisId: number) {
  return request(
    `${API.SAMPLES}sample/str/${analysisId}/`,
    METHOD.POST,
    param,
    {}
  )
}

// AnalysisVariants
export async function getAnalysisVariants(param: any, analysisId: number) {
  return request(
    `${API.SAMPLES}analysis/${analysisId}/`,
    METHOD.POST,
    param,
    {}
  )
}

// dashbord
export const fetchUsersCharts = async () => {
  const response = await axios.get(`/api/users/chart/`)

  return response.data
}

export const fetchSamplesCharts = async () => {
  const response = await axios.get(`/api/samples/chart/`)

  return response.data
}

// patientSample
export const fetchPatientBySampleId = async (sampleId: number) => {
  const response = await axios.get(
    `/api/patient-samples/getPatientBySampleId/`,
    {
      params: {
        sampleId
      }
    }
  )

  return response.data
}

// variant-details
export const fetchVariantDetails = async (params: {
  sampleId: number
  variants: any
}) => {
  const response = await axios.post(
    `/api/variants/sample/${params.sampleId}/variant_detail/`,
    {
      ...params.variants,
      chrom: String(params.variants.chrom)
    }
  )

  return response.data
}

export const fetchVariantsDetails = async (params: {
  sampleId: number
  variants: any
}) => {
  const response = await axios.post(
    `/api/variants/sample/${params.sampleId}/variants_detail/`,
    params.variants
  )

  return response.data
}

export const fetchAnalysisVariantDetails = async (params: {
  analysisId: number
  variants: any
}) => {
  const response = await axios.post(
    `/api/variants/analysis/${params.analysisId}/variant_detail/`,
    {
      ...params.variants,
      chrom: String(params.variants.chrom)
    }
  )

  return response.data
}

export const fetchAnalysisVariantsDetails = async (params: {
  sampleId: number
  variants: any
}) => {
  const response = await axios.post(
    `/api/variants/analysis/${params.sampleId}/variants_detail/`,
    params.variants
  )

  return response.data
}

export const fetchOMIM = async (params: { geneNames: string[] }) => {
  const response = await axios.post(`/api/variants/gene_omim/`, params)

  return response.data
}

export const editAcmgClass = async (
  params: UpdateVariantACMGClassificationDto
) => {
  const response = await axios.post(
    `/api/variants/custom/acmg_classification/`,
    params
  )

  return response.data
}

export const editClass = async (params: UpdateVariantClassificationDto) => {
  const response = await axios.post(
    `/api/variants/custom/classification/`,
    params
  )

  return response.data
}

export const getArticles = async (params: GetArticlesDto) => {
  const response = await axios.post(`/api/btg-api/get-articles/`, params, {
    timeout: 0
  })

  return response.data
}

export const getBatchArticles = async (params: GetArticlesDto[]) => {
  const response = await axios.post(
    `/api/btg-api/get-batch-articles/`,
    {
      params: params
    },
    {
      timeout: 0
    }
  )

  return response.data
}

export const getGoogleArticles = async (params: GetArticlesDto) => {
  const response = await axios.post(
    `/api/btg-api/get-google-articles/`,
    params,
    {
      timeout: 0
    }
  )

  return response.data
}

export const getLitvarArticles = async (rsId: string) => {
  const response = await axios.get(`/api/btg-api/litvar/${rsId}/`)

  return response.data
}

export const getBatchLitvarArticles = async (rsIds: string[]) => {
  const response = await axios.post(`/api/btg-api/get-batch-litvar/`, {
    rsIds: rsIds
  })

  return response.data
}

export const getVarArticles = async (params: GetVariantLiteratureDto) => {
  const response = await axios.post(
    `/api/btg-api/get-variant-articles/`,
    params
  )

  return response.data
}

export const getLitVarArticles = async (rsId: string, page: number) => {
  const response = await axios.get(`/api/btg-api/litvar/${rsId}/?page=${page}`)

  return response.data
}

export const getTermByClinicalInfo = async (
  params: GetTermsByClinicalInfoDto
) => {
  const response = await axios.post(
    `/api/btg-api/get-terms-by-clinical-info/`,
    params
  )

  return response.data
}

export const translate = async (params: TranslateDto) => {
  const response = await axios.post(`/api/translate/`, params)

  return response.data
}

export const translates = async (params: TranslateBulkDto) => {
  const response = await axios.post(`/api/translate/bulk/`, params)

  return response.data
}

export const fetchMitomap = async (params: GetMitoMapDataDto[]) => {
  const response = await axios.post(`/api/variants/mitomap/`, params)

  return response.data
}

export const createComment = async (params: CreateVariantCommentDto) => {
  const response = await axios.post(`/api/variants/comment/`, params)

  return response.data
}

export const getAllComments = async (params: CreateVariantCommentDto) => {
  const response = await axios.post(`/api/variants/getAllComments/`, params)

  return response.data
}

export const createCommentImport = async (params: CreateVariantCommentDto) => {
  const response = await axios.post(`/api/variants/comment-import/`, params)

  return response.data
}

export const updateComment = async (value: {
  id: string
  params: UpdateVariantCommentDto
}) => {
  const response = await axios.patch(
    `/api/variants/comment/${value.id}/`,
    value.params
  )

  return response.data
}

export const removeComment = async (id: string) => {
  const response = await axios.delete(`/api/variants/comment/${id}/`)

  return response.data
}

export const getVariantIndexData = async (params: GetManyVariantIndexDto) => {
  const response = await axios.post(`/api/variants/indexes/get/`, params)

  return response.data
}

// categories
export const createCategory = async (param: Category) => {
  const response = await axios.post('/api/categories/', param)

  return response.data
}

export const getCategory = async () => {
  const response = await axios.get('/api/categories/')

  return response.data
}

export const editCategory = async (param: Category) => {
  const response = await axios.patch('/api/categories/', param)

  return response.data
}

export const deleteCategory = async (id: number) => {
  const response = await axios.delete(`/api/categories/${id}/`)

  return response.data
}

// variant-list
export const createVariantList = async (param: VariantList) => {
  const response = await axios.post('/api/variant-list/', param)

  return response.data
}

export const getVariantList = async (params: { categoryId: number }) => {
  const response = await axios.get('/api/variant-list/', { params })

  return response.data
}

export const editVariantList = async (param: VariantList) => {
  const response = await axios.patch(`/api/variant-list/${param.id}`, param)

  return response.data
}

export const deleteVariantList = async (id: number) => {
  const response = await axios.delete(`/api/variant-list/${id}/`)

  return response.data
}

// roh-list
export const fetchRohList = async (id: number) => {
  const response = await axios.get(`/api/variants/rohList/${id}/`)

  return response.data
}

// share-sample
export const createShareSample = async (param: ShareSample) => {
  const response = await axios.post('/api/share-samples/', param)

  return response.data
}

export const getShareSample = async (params: { categoryId: number }) => {
  const response = await axios.get('/api/share-samples/', { params })

  return response.data
}

export const editShareSample = async (param: ShareSample) => {
  const response = await axios.patch('/api/share-samples/', param)

  return response.data
}

export const deleteShareSample = async (id: number) => {
  const response = await axios.delete(
    `/api/share-samples/removeBySampleIdAndUserId/${id}/`
  )

  return response.data
}

export const deleteShareSample2 = async (id: number) => {
  const response = await axios.delete(`/api/share-samples/${id}/`)

  return response.data
}

export const exportAnalysisVariants = async (id: number) => {
  const response = await axios.post(
    `/api/variants/analysis/exportAnalysisVariants/${id}/`,
    {},
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  return response.data
}

export const exportAllVariantToCsv = async (params: {
  id: number
  type: string
}) => {
  const response = await axios.post(
    `/api/variants/sample/exportAllVariantToCsv/${params.id}/`,
    {
      type: params.type
    },
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )

  return response.data
}

export const uploadpdf = async (params: { file: FormData; url: string }) => {
  const response = await axios.post(
    `/api/ai/loadpdf/?url=${params.url}`,
    params.file
  )

  return response.data
}

export const analyzeArticle = async (param: AnalyzeArticleDto) => {
  const response = await axios.post(`/api/ai/analyzeArticle/`, param)

  return response.data
}

export const analyzeVariant = async (param: AnalyzeVariantDto) => {
  const response = await axios.post(`/api/ai/analyzeVariant/`, param)

  return response.data
}

export const chatClaude = async (param: ChatClaudeDto) => {
  const response = await axios.post(`/api/ai/chatClaude/`, param)

  return response.data
}

export async function* getChatgpt_Multurn_qa(param: ChatClaudeDto) {
  console.log(param)
  const response = await fetch('/api/ai/chatClaude/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      messages: param.messages
    })
  })

  console.log(response)

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  if (response.body) {
    const reader = response.body.getReader()

    let result = ''
    let done = false

    while (!done) {
      const { value, done: streamDone } = await reader.read()

      if (value) {
        const decoder = new TextDecoder()

        result += decoder.decode(value)

        const lines = result.split('\n')

        if (lines.length !== 1) result = lines.pop() ?? ''
        else result = ''

        for (const line of lines) {
          try {
            yield {
              content: line
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      if (streamDone) {
        done = true
      }
    }
  }
}

// gene_info
export const getExpression = async (geneName: string) => {
  const response = await axios.get(`/api/gene-info/expression/${geneName}/`)

  return response.data
}

// task-wes-cnv-batch
export const createCnvBatch = async (params: TaskWesCnvBatchDto) => {
  const response = await axios.post(`/api/task-wes-cnv-batch/`, params)

  return response.data
}

export const fetchCnvBatchh = async (params: TaskWesCnvBatchDto) => {
  const response = await axios.get(`/api/task-wes-cnv-batch/`, {
    params
  })

  return response.data
}

export const fetchCnvBatchhById = async (id: number) => {
  const response = await axios.get(`/api/task-wes-cnv-batch/${id}`)

  return response.data
}

// analysis-items
export const createAnalysisItems = async (params: AnalysisItem) => {
  const response = await axios.post(`/api/analysis-items/`, params)

  return response.data
}

// sample-labels
export const createSampleSamples = async (param: SampleLabels) => {
  const response = await axios.post('/api/sample-labels/', param)

  return response.data
}

export const getSampleSamples = async (params: any) => {
  const response = await axios.get('/api/sample-labels/', { params })

  return response.data
}

export const editSampleSamples = async (param: SampleLabels) => {
  const response = await axios.patch('/api/sample-labels/', param)

  return response.data
}

export const deleteSampleSamples = async (id: number) => {
  const response = await axios.delete(`/api/sample-labels/${id}/`)

  return response.data
}

// venn-data
export const getVennData = async (params: GetVennDataDto) => {
  const response = await axios.post('/api/venn-data/fetch/', params)

  return response.data
}
