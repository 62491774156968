const API = {
  MOCK: `/api/mock`,
  SAMPLES: `/api/variants/`,
  PROJECTS: `/api/projects/`
}

const ExternalLinks = {
  ncbi: {
    nuccore: 'https://www.ncbi.nlm.nih.gov/nuccore',
    pubmed: 'https://www.ncbi.nlm.nih.gov/pubmed',
    articles: 'https://www.ncbi.nlm.nih.gov/pmc/articles',
    snp_ref: 'https://www.ncbi.nlm.nih.gov/SNP/snp_ref.cgi',
    variation: 'https://www.ncbi.nlm.nih.gov/clinvar/variation'
  },
  genome: 'http://genome.ucsc.edu/cgi-bin/hgTracks',
  omim: 'https://www.omim.org/entry',
  omimSearch: 'https://omim.org/search',
  gnomad: 'http://gnomad-sg.org/variant',
  gtex: 'https://gtexportal.org/home/gene',
  mastermind: 'https://mastermind.genomenon.com/detail',
  decipher: 'https://www.deciphergenomics.org/gene',
  Clingen: 'https://search.clinicalgenome.org/kb/genes/HGNC:'
}
export { API, ExternalLinks }
